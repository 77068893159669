import { getStatementFilterDates } from '../getStatementFilterDates';

describe('getStatementFilterDates', () => {
  it('should return statement filter dates with week filter', () => {
    const custom = false;
    const selectedStartDate = null;
    const selectedEndDate = null;
    const filter = 'week';

    const result = getStatementFilterDates(
      custom,
      filter,
      selectedStartDate,
      selectedEndDate,
    );

    expect(result).toHaveProperty('startDate');
    expect(result).toHaveProperty('endDate');
    expect(result).toHaveProperty('selectedLimit');
    expect(result.startDate).toBeInstanceOf(Date);
    expect(result.endDate).toBeInstanceOf(Date);
    expect(result.selectedLimit).toEqual({ text: 'Last week', value: 'week' });
  });

  it('should return statement filter dates with month filter', () => {
    const custom = false;
    const selectedStartDate = null;
    const selectedEndDate = null;
    const filter = 'month';

    const result = getStatementFilterDates(
      custom,
      filter,
      selectedStartDate,
      selectedEndDate,
    );

    expect(result).toHaveProperty('startDate');
    expect(result).toHaveProperty('endDate');
    expect(result).toHaveProperty('selectedLimit');
    expect(result.startDate).toBeInstanceOf(Date);
    expect(result.endDate).toBeInstanceOf(Date);
    expect(result.selectedLimit).toEqual({
      text: 'Last month',
      value: 'month',
    });
  });

  it('should return statement filter dates with custom filter', () => {
    const custom = true;
    const selectedStartDate = new Date('2023-01-01');
    const selectedEndDate = new Date('2023-01-31');
    const filter = '';

    const result = getStatementFilterDates(
      custom,
      filter,
      selectedStartDate,
      selectedEndDate,
    );

    expect(result).toHaveProperty('startDate');
    expect(result).toHaveProperty('endDate');
    expect(result).toHaveProperty('selectedLimit');
    expect(result.startDate).toBeInstanceOf(Date);
    expect(result.endDate).toBeInstanceOf(Date);
    expect(result.selectedLimit).toHaveProperty('text');
    expect(result.selectedLimit).not.toHaveProperty('value');
    expect(typeof result.selectedLimit.text).toBe('string');
  });
});
