import moment from 'moment';
import { getLimitOptions } from './getLimitOptions';
import { __ } from '../../../i18n';

const months = [
  __('jan'),
  __('feb'),
  __('mar'),
  __('apr'),
  __('may'),
  __('jun'),
  __('jul'),
  __('aug'),
  __('sep'),
  __('oct'),
  __('nov'),
  __('dec'),
];

export const getStatementFilterDates = (
  custom,
  filter,
  selectedStartDate,
  selectedEndDate,
) => {
  const limitOptions = getLimitOptions();

  let startDate = new Date(moment().subtract(30, 'days'));
  let endDate = new Date(moment());
  let selectedLimit = { text: __('Last %s days', 30) };

  if (!custom && filter === 'week') {
    const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
    const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');

    endDate = new Date(endOfLastWeek);
    startDate = new Date(startOfLastWeek);
    selectedLimit = limitOptions.find((option) => option.value === 'week');
  } else if (!custom && filter === 'month') {
    const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
    const startOfLastMonth = moment().subtract(1, 'month').startOf('month');

    endDate = new Date(endOfLastMonth);
    startDate = new Date(startOfLastMonth);
    selectedLimit = limitOptions.find((option) => option.value === 'month');
  } else {
    endDate = selectedEndDate ? new Date(selectedEndDate) : new Date();
    startDate = selectedStartDate
      ? new Date(selectedStartDate)
      : new Date(moment().startOf('month'));

    if (custom) {
      selectedLimit = {
        text: __(
          '%s to %s',
          `${startDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[startDate.getMonth()]}`,
          `${endDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[endDate.getMonth()]}`,
        ),
      };
    } else {
      selectedLimit = limitOptions[
        limitOptions.findIndex((option) => option.value === filter)
      ] || { text: __('This month') };
    }
  }

  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  return { startDate, endDate, selectedLimit };
};
